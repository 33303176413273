import { Box, Link, LinkList, LinkListHeading, ListItem } from '@vp/swan'
import { PageSeoDescription, PageSeoTitle } from 'components/seo/page-seo.component'
import { PageProps } from 'gatsby'
import { FC } from 'react'
import { PageContextType } from 'types/application.types'

const NotFoundPage: FC<PageProps<unknown, PageContextType>> = () => (
  <Box align="center" mt={10}>
    <PageSeoTitle content="AME services: Page not found" />
    <PageSeoDescription content="Requested page not found" />
    <LinkListHeading>Available pages</LinkListHeading>
    <LinkList>
      <ListItem>
        <Link to="en-us/ame/upload">EN - Upload</Link>
      </ListItem>
      <ListItem>
        <Link to="es-us/ame/upload">ES - Upload</Link>
      </ListItem>
      <ListItem>
        <Link to="/en-ca/ame/upload">EN-CA - Upload</Link>
      </ListItem>
      <ListItem>
        <Link to="/fr-ca/ame/upload">FR-CA - Upload</Link>
      </ListItem>
    </LinkList>
  </Box>
)
export default NotFoundPage
